import { graphql } from 'gatsby';
import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Layout } from '../../components/layout/layout';
import { textOptions } from '../../util/rich-text';
import {
  DesktopAuthorCard,
  MobileAuthorCard,
} from '../../components/cards/author-card';
import { PageHeading } from '../../components/layout/page-heading';
import BackButton from '../../components/back-button';

const EventUpdatePost = ({ data }) => {
  const blogPost = data.contentfulEventUpdatePost;
  return (
    <Layout title={blogPost.title} description={blogPost.summary.summary}>
      <div className="max-w-screen-xl mx-auto px-4 md:px-0">
        <BackButton title="Events" link="/events" />
        <div className="mt-6">
          <div className="flex">
            <DesktopAuthorCard author={blogPost.author} />
            <main className="max-w-screen-md md:px-4 md:ml-8">
              <PageHeading title={blogPost.title} />
              <MobileAuthorCard author={blogPost.author} />
              <div className="mt-6">
                {blogPost.post && renderRichText(blogPost.post, textOptions)}
              </div>
            </main>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EventUpdatePost;

export const query = graphql`
  query ($slug: String!) {
    contentfulEventUpdatePost(slug: { eq: $slug }) {
      title
      post {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            fixed(width: 1200) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
      summary {
        summary
      }
      createdAt(formatString: "Do MMMM, YYYY")
      author {
        name
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        displayPicture {
          fixed(width: 400) {
            width
            height
            src
            srcSet
          }
        }
      }
    }
  }
`;
